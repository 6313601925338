/*
SCREENSIZE
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 25, 2022
UPDATE DATE: May 25, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ScreenSize | Check your screen size!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > ul{
    margin: -5px;
    overflow: auto;

    > li{
      position: relative;
      padding: 5px;
      float: left;
      box-sizing: border-box;
      width: 50%;

      &:nth-child(1){
        > a{
          float: left;
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;

          > svg{
            display: block;
            width: 164px;
            height: 40px;
          }
        }

        > ul{
          margin: -5px -5px -5px 174px;

          > li{
            width: 100% !important;
          }
        }
      }

      > ul{
        overflow: auto;
        margin: -5px;

        > li{
          position: relative;
          padding: 5px;
          float: left;
          width: 37.5%;
          box-sizing: border-box;
          
          &:nth-child(3){
            width: 25%;
          }

          > .unit{
            position: absolute;
            top: 5px;
            right: 5px;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 40px;
            background: #CCC;
            color: #FFF;
            border-radius: 0 5px 5px 0;
            font-size: 14px;
            font-weight: bold;
            transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -webkit-transition: all 200ms ease-in-out;
          }
        }
      }

      label{
        position: absolute;
        top: 1px;
        left: 16px;
        background: #FFF;
        padding: 0 5px;
        font-size: 11px;
        line-height: 11px;
      }

      input{
        border: 1px solid #CCC;
        height: 40px;
        border-radius: 5px;
        padding: 4px 55px 0 15px;
        background: #FFF;
        font-size: 14px;

        &.no-unit{
          padding: 4px 15px 0 15px;
        }

        &:hover,
        &:focus{
          border: 1px solid #999;

          ~ .unit{
            background: #999;
          }
        }
      }

      button{
        background: #666;
        color: #FFF;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;

        &:hover{
          background: #333;
        }

        // span{
        //   display: block;
        //   font-size: 9px;
        //   font-weight: normal;
        //   margin: 0 0 1px 0;
        // }

        > svg{
          display: inline-block;
          vertical-align: middle;
          margin: -3px 0 0 5px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}

ul.screen-mid{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  padding: 90px 20px 75px 20px;
  margin: 0;

  > li{
    float: left;
    padding: 20px 10px;
    width: 100%;
    box-sizing: border-box;

    .simulation{
      position: relative;
      margin: 10px auto 0 auto;
      width: 150px;
      height: 150px;
      border: 1px dashed #CCC;
  
      div{
        background: #999;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        margin: 0 auto;
  
        span{
          position: absolute;
          color: #FFF;
          top: 50%;
          left: 0;
          right: 0;
          transform: translate(0, -50%);
          font-weight: 100;
          font-size: 18px;
          line-height: 18px;
  
          strong{
            display: block;
            font-size: 10px;
            line-height: 10px;
            margin: 2.5px 0 0 0;
          }
        }
      }
    }
  
    > ul{
      text-align: center;
      margin: 0 -10px -10px -10px;
      
      > li{
        display: inline-block;
        font-weight: 100;
        color: #666;
        font-size: 64px;
        line-height: 68px;
        padding: 10px;
  
        &.lite{
          font-size: 44px;
          line-height: 48px;
        }
  
        span{
          display: block;
          margin: -2.5px 0 0 0;
          font-size: 12px;
          line-height: 14px;
          color: #999;
        }
      }
    }
  }
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  ul.screen-mid{
    font-size: 11px;
    line-height: 15px;

    > li{
      padding: 10px;
      width: 30%;

      &:nth-child(3){
        width: 40%;
      }
      
      .simulation{
        margin: 10px auto 0 auto;
        width: 100px;
        height: 100px;
  
        div{
          span{
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
          }
        }
      }

      > ul{
        display: inline-block;
        margin: -5px;
        padding: 5px 20px 0 20px;
        
        > li{
          font-size: 32px;
          line-height: 36px;
          padding: 5px;
    
          &.lite{
            font-size: 32px;
            line-height: 36px;
          }
    
          span{
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  footer{
    padding: 20px 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  header{
    > ul{
      > li{
        width: 100%;

        &:nth-child(1){
          > a{
            > svg{
              width: 113px;
            }
          }

          > ul{
            margin: -5px -5px -5px 123px;
          }
        }
      }
    }
  }

  ul.screen-mid{
    font-size: 18px;
    line-height: 22px;
    padding: 140px 20px 70px 20px;

    > li{
      padding: 10px;
      
      .simulation{
        margin: 10px auto 0 auto;
        width: 130px;
        height: 130px;
  
        div{
          span{
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    
      > ul{
        > li{
          font-size: 42px;
          line-height: 46px;
    
          &.lite{
            font-size: 32px;
            line-height: 36px;
          }
    
          span{
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }

  footer{
    padding: 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/